var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main attachment-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "附件名称",
                  value: _vm.utils.isEffectiveCommon(_vm.fileData.fileName)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "上传人",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.fileData.createUserName
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "上传时间",
                  value: _vm.utils.dateTimeFormat(_vm.fileData.uploadTime)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "备注",
                  value: _vm.utils.isEffectiveCommon(_vm.fileData.remarks)
                }
              }),
              _c("file-list", {
                attrs: { "file-list": _vm.fileList, title: "下载" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 附件详情 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }