<template>
  <div class="van-main attachment-detail">
    <div class="area-title">
      <p class="title">
        附件详情
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="附件名称" :value="utils.isEffectiveCommon(fileData.fileName)" />
        <van-cell title="上传人" :value="utils.isEffectiveCommon(fileData.createUserName)" />
        <van-cell title="上传时间" :value="utils.dateTimeFormat(fileData.uploadTime)" />
        <van-cell title="备注" :value="utils.isEffectiveCommon(fileData.remarks)" />
        <file-list :file-list="fileList" title="下载" />
      </van-cell-group>
    </div>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Toast, uploader } from 'vant'
import BackButton from '@/components/back-button'
import fileList from '@/components/file-list'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [uploader.name]: uploader,
    fileList
  },
  data () {
    return {
      id: this.$route.query.id,
      fileData: {},
      fileList: []
    }
  },
  computed: {
  },
  created () {
    this.getDetial()
  },
  methods: {
    getDetial () {
      this.api.base.file.getDetailByFileId({ fileId: this.id }).then(result => {
        this.fileData = result.data.value
        this.fileList.push(this.fileData)
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
